import { inject, Pipe, type PipeTransform } from '@angular/core';

import {
  DateService,
  type FormatDistanceOptions,
  type FormatOptions,
  type FormatRelativeOptions,
} from '@core/services/date.service';

@Pipe({
  name: 'ccDateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  private readonly dateService = inject(DateService);

  transform(date: string | number | Date, options: FormatOptions): string {
    const timezoneDate = this.dateService.representLocalDateInProfileTimezoneDate(new Date(date));
    return this.dateService.format(timezoneDate, options);
  }
}

@Pipe({
  name: 'ccDateFormatRelative',
  standalone: true,
})
export class DateFormatRelativePipe implements PipeTransform {
  private readonly dateService = inject(DateService);

  transform(
    date: string | number | Date,
    options?: FormatRelativeOptions & {
      baseDate?: string | number | Date;
    },
  ): string {
    const timezoneDate = this.dateService.representLocalDateInProfileTimezoneDate(new Date(date));
    const baseTimezoneDate = this.dateService.representLocalDateInProfileTimezoneDate(
      options?.baseDate ? new Date(options.baseDate) : new Date(),
    );

    return this.dateService.formatRelative(timezoneDate, baseTimezoneDate, options);
  }
}

@Pipe({
  name: 'ccDateFormatDistance',
  standalone: true,
})
export class DateFormatDistancePipe implements PipeTransform {
  private readonly dateService = inject(DateService);

  transform(
    date: string | number | Date,
    options?: FormatDistanceOptions & {
      baseDate?: string | number | Date;
    },
  ): string {
    const timezoneDate = this.dateService.representLocalDateInProfileTimezoneDate(new Date(date));
    const baseTimezoneDate = this.dateService.representLocalDateInProfileTimezoneDate(
      options?.baseDate ? new Date(options.baseDate) : new Date(),
    );

    return this.dateService.formatDistance(timezoneDate, baseTimezoneDate, options);
  }
}
